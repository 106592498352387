import React, { useState, useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";

import About from "./Pages/about/About";
import ContactUs from "./Pages/contact/Contact";

import Project from "./Pages/Projects/Project";
import Gallery from "./Pages/gallery/gallery";
import Error404 from "./Pages/Erorr/Error";
import { Toaster } from "react-hot-toast";
import { AuthContext } from "./utils/AuthContext";
import { useTranslation } from "react-i18next";
import Main from "./Pages/Jobs/Main";
import MainForm from "./Pages/JobApp/MainForm";
import CheckJobApp from "./Pages/JobApp/checkJobApp";

function App() {
  const [loginUser, setLoginUser] = useState(null);
  let data = localStorage.getItem("loginUser");

  useEffect(() => {
    data && setLoginUser(JSON.parse(data));
  }, []);
  const { i18n } = useTranslation();

  useEffect(() => {

    // document.addEventListener('contextmenu', (event) => event.preventDefault()); 
    // document.addEventListener('keydown', (event) => {
    //   if (event.ctrlKey && (event.key === 'u' || event.key === 'U')) {
    //     event.preventDefault();
    //   }
    //   if (event.ctrlKey && event.shiftKey && (event.key === 'I' || event.key === 'J' || event.key === 'C')) {
    //     event.preventDefault();
    //   }
    //   if (event.key === 'F12') {
    //     event.preventDefault();
    //   }
    // });
   
    

    if (i18n.language === 'en') {
      document.body.classList.add('ltr');
      document.body.classList.remove('rtl');
    } else {
      document.body.classList.add('rtl');
      document.body.classList.remove('ltr');
    }
  }, [i18n.language]);

  return (
    <div>
      <AuthContext.Provider value={{ loginUser, setLoginUser }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" exact={true} element={<Home />} />
            <Route path="/about" exact={true} element={<About />} />
            <Route path="/contact" exact={true} element={<ContactUs />} />
            <Route path="/status" exact={true} element={<CheckJobApp />} />
            <Route path="/project" exact={true} element={<Project />} />
            <Route path="/apply" exact={true} element={<MainForm/>} />
            <Route path="/hire" exact={true} element={<Main/>} />
            <Route path="/gallery" exact={true} element={<Gallery />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
          <Toaster />
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
