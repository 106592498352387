import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Footer from '../../Components/Footer/Footer';

const GalleryInfo = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        const response = await fetch('https://api.asiamehr.com/api/v1/gallerypage');
        const data = await response.json();
        if (response.ok) {
          setGalleryItems(data.data.gallery);
        } else {
          console.error('Failed to fetch gallery items', data);
        }
      } catch (error) {
        console.error('Error fetching gallery items:', error);
      }
    };

    fetchGalleryItems();
  }, [i18n.language]);

  return (
    <>
    <Helmet>
      <title>{t('helmet.gallery')}</title>
      <meta name="description" content={t('helmet.galleryDescription')} />
      <meta name="keywords" content="gallery, images, photos, asia mehr fardad" />
    </Helmet>

    <Box sx={{ 
      mt: { xs: 2, sm: 4 }, 
      px: { xs: 2, sm: 4 }, 
      maxWidth: '100%', 
      overflowX: 'hidden' ,
      height:"auto",
      marginBottom:"20px"
    }}>
      <Grid container spacing={2}>
        {galleryItems?.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Paper
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                boxShadow: 3,
                borderRadius: 2,
                transition: 'transform 0.3s',
                '&:hover': { transform: 'scale(1.03)' },
              }}
            >
              <Box
                component="img"
                src={`https://api.asiamehr.com${item.image}`}
                alt={item.title}
                sx={{
                  width: '100%',
                  aspectRatio: '4/3',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  p: 2,
                  textAlign: 'center',
                }}
              >
                <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                  {t(`gallery.${item._id}.title`, { defaultValue: item.title })}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                  {t(`gallery.${item._id}.description`, { defaultValue: item.description })}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
    <Footer />
  </>
  );
};

export default GalleryInfo;
