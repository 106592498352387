import React from "react";
import { Box, Typography, Button } from "@mui/material";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { useNavigate } from 'react-router-dom';
const RecruitmentBanner = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#1D372A",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          textAlign: "center",
          padding: "20px",
        }}
      >

        <Box
          sx={{
            backgroundColor: "#D1B85A",
            width: { xs: "100px", sm: "120px", md: "150px" },
            height: { xs: "100px", sm: "120px", md: "150px" },
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <HandshakeIcon sx={{ fontSize: { xs: "50px", sm: "65px", md: "80px" }, color: "#1D372A" }} />
        </Box>

        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            marginBottom: "30px",
            fontSize: { xs: "20px", sm: "28px", md: "36px" },
          }}
        >
          استخدام کارخانه اسیامهر
        </Typography>


        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: "10px", sm: "20px" },
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => navigate('/hire')}
            sx={{
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
              width: { xs: "100%", sm: "200px" },
              height: "50px",
              color: "white",
              borderColor: "white",
              borderRadius: "8px",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: "#1D372A",
              },
            }}
          >
            فرصت‌های شغلی
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate('/status')} // Replace '/status' with your actual route
            sx={{
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
              width: { xs: "100%", sm: "200px" },
              height: "50px",
              color: "white",
              borderColor: "white",
              borderRadius: "8px",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderColor: "white"
              },
              transition: "all 0.3s ease",
              textTransform: "none", // Prevents uppercase transformation
              fontWeight: "500" // Medium weight for better readability
            }}
          >
            پیگیری استخدام
          </Button>
        </Box>
      </Box>
    </>

  );
};

export default RecruitmentBanner;
