import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography, IconButton, Grid } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram'; 
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { LinkedIn } from '@mui/icons-material';

function Footer() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        color: '#000',
        padding: { xs: '2rem', md: '4rem' },
        textAlign: 'center',
      }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            color:"#000",
            mb: 2,
            borderBottom: '3px solid #c1a122',
            display: 'inline-block',
            paddingBottom: '5px',
          }}
        >
          {t('footer.title')}
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: '#c1a122',
              mb: 1,
              borderBottom: '2px solid white',
              display: 'inline-block',
              paddingBottom: '5px',
            }}
          >
            {t('contact.title')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#000', mb: 1 }}>
            {t('contact.address_line2')}
          </Typography>
          <Typography variant="body2" sx={{ color: '#000' }}>
            {t('contact.address')}
          </Typography>
        </Box>

        {/* Social Media Links */}
        <Grid container spacing={2} justifyContent="center">
          {[ 
            { icon: <InstagramIcon fontSize="large" />, link: 'https://www.instagram.com/asiamehr_company/', label: 'instagramLink' },
            { icon: <LinkedIn fontSize="large" />, link: 'https://www.linkedin.com/in/asiamehr', label: 'linkedinLink' },
            { icon: <PhoneIcon fontSize="large" />, link: 'tel:9000090', label: 'callLink' },
            { icon: <AlternateEmailIcon fontSize="large" />, link: 'mailto:info@asiamehr.com', label: 'emailLink' },
          ].map((item, index) => (
            <Grid item key={index}>
              <IconButton 
                sx={{ 
                  color: '#000',
                  transition: '0.3s',
                  '&:hover': { color: '#c1a122' },
                }} 
                component="a"
                href={item.link}
                aria-label={t(item.label)} 
              >
                {item.icon}
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Footer Bottom Section */}
      <Box sx={{ borderTop: '1px solid #c1a122', pt: 3 }}>
      <Link to="#" style={{ color: '#c1a122' }}>{t('footer.title')}</Link>
        <Typography variant="body2" sx={{ color: '#000' }}>
          {t('footer.footer')} 
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={3} justifyContent="center">
            {[ 
              { label: t('footer.home'), path: '/' },
              { label: t('footer.about'), path: '/about' },
              { label: t('footer.contact'), path: '/contact' },
              { label: t('footer.blog'), path: '/gallery' },
            ].map((item, index) => (
              <Grid item key={index}>
                <Link to={item.path} style={{
                  color: '#000',
                  textDecoration: 'none',
                  transition: '0.3s',
                  fontWeight: '500',
                  '&:hover': { color: '#c1a122' }
                }}>
                  {item.label}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
