import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Link,
  Chip
} from '@mui/material';
import RecruitmentBanner from '../Jobs/Banner';
import Footer from '../../Components/Footer/Footer';
import Nav from '../../Components/NavBar/Nav';
import { useNavigate } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';
const CheckJobApp = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const getStatusMessage = (status, jobTitle = "موقعیت شغلی مورد نظر") => {
    const messages = {
      "پذیرفته شده": {
        message: ` متقاضی محترم، درخواست شما برای موقعیت شغلی "${jobTitle}" پذیرفته شده است. همکاران ما به زودی با شما تماس خواهند گرفت.`,
        color: "success"
      },
      "رد شده": {
        message: `متقاضی محترم، متأسفانه درخواست شما برای موقعیت شغلی "${jobTitle}" پذیرفته نشده است.`,
        color: "error"
      },
      "در حال بررسی": {
        message: `متقاضی محترم، درخواست شما برای موقعیت شغلی "${jobTitle}" در حال بررسی است. لطفاً شکیبا باشید.`,
        color: "warning"
      },
      "نامشخص": {
        message: `متقاضی محترم، وضعیت درخواست شما برای ${jobTitle} مشخص نیست. لطفاً با پشتیبانی تماس بگیرید.`,
        color: "default"
      }
    };
    return messages[status] || messages["نامشخص"];
  };

  const handleCheck = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResult(null);
    setError('');

    try {
      const res = await fetch('https://api.asiamehr.com/api/v1/jobapp/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone }),
      });

      const data = await res.json();

      if (!res.ok || !data.success) {
        throw new Error(data.message || 'خطا در دریافت اطلاعات');
      }

      setResult(data.data);
    } catch (err) {
      setError(err.message || 'خطا در سرور');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Nav/>
    <Box
        sx={{
          backgroundColor: "#1D372A",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          textAlign: "center",
          padding: "20px",
        }}
      >

        <Box
          sx={{
            backgroundColor: "#D1B85A",
            width: { xs: "100px", sm: "120px", md: "150px" },
            height: { xs: "100px", sm: "120px", md: "150px" },
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <WorkIcon sx={{ fontSize: { xs: "50px", sm: "65px", md: "80px" }, color: "#1D372A" }} />
        </Box>

        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            marginBottom: "30px",
            fontSize: { xs: "20px", sm: "28px", md: "36px" },
          }}
        >
      پیگیری رزومه 
        </Typography>


        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: "10px", sm: "20px" },
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => navigate('/hire')}
            sx={{
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
              width: { xs: "100%", sm: "200px" },
              height: "50px",
              color: "white",
              borderColor: "white",
              borderRadius: "8px",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: "#1D372A",
              },
            }}
          >
            فرصت‌های شغلی
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate('/status')} // Replace '/status' with your actual route
            sx={{
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
              width: { xs: "100%", sm: "200px" },
              height: "50px",
              color: "white",
              borderColor: "white",
              borderRadius: "8px",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderColor: "white"
              },
              transition: "all 0.3s ease",
              textTransform: "none", // Prevents uppercase transformation
              fontWeight: "500" // Medium weight for better readability
            }}
          >
            پیگیری استخدام
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: 500,
          height: 'auto',
          margin: 'auto',
          my: 6,
          px: 2,
          direction: 'rtl',
         
        }}
      >
        <Card
          elevation={0}
          sx={{
            border: '1px solid #e0e0e0',
            borderRadius: 2,
            boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
            backgroundColor: '#fafafa'
          }}
        >
          <CardContent sx={{ p: 4 }}>
            <Typography
              variant="h5"
              gutterBottom
              align="center"
              sx={{
                fontWeight: 600,
                color: 'rgb(107, 142, 35)', // Olive green
                mb: 4,
                fontSize: '1.5rem'
              }}
            >
              پیگیری وضعیت استخدام
            </Typography>

            <form onSubmit={handleCheck}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="شماره تماس ثبت شده"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                margin="normal"
                inputProps={{
                  dir: 'ltr',
                  style: { fontSize: '1.1rem' }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 1,
                    fontSize: '1.1rem'
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: '1.1rem',
                    right: 0,
                    left: 'auto'
                  },
                  mb: 3
                }}
                size="medium"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading || !phone}
                sx={{
                  mt: 2,
                  py: 2,
                  fontSize: '1.1rem',
                  backgroundColor: 'rgb(189, 155, 25)', // Gold
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: 'rgb(165, 135, 20)',
                  },
                  '&:disabled': {
                    backgroundColor: '#e0e0e0'
                  }
                }}
              >
                {loading ? <CircularProgress size={26} color="inherit" /> : 'بررسی وضعیت'}
              </Button>
            </form>

            {error && (
              <Alert
                severity="error"
                sx={{
                  mt: 3,
                  borderRadius: 1,
                  fontSize: '1rem',
                  direction: 'rtl'
                }}
              >
                {error}
              </Alert>
            )}

            {result && (
              <Box mt={4} sx={{ borderTop: '1px solid #e0e0e0', pt: 3 }}>
                <Typography variant="h6" sx={{ mb: 2, fontSize: '1.2rem' }}>
                  <strong>متقاضی:</strong> {result.fullName}
                </Typography>

                {result.jobRequest?.title && (
                  <Typography variant="h6" sx={{ mb: 2, fontSize: '1.2rem' }}>
                    <strong>موقعیت شغلی:</strong> {result.jobRequest.title}
                  </Typography>
                )}

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6" sx={{ ml: 1, fontSize: '1.2rem' }}>
                    <strong>وضعیت:</strong>
                  </Typography>
                  <Chip
                    label={result.status || 'نامشخص'}
                    color={getStatusMessage(result.status, result.jobRequest?.title).color}
                    size="medium"
                    sx={{
                      fontWeight: 600,
                      fontSize: '1rem',
                      ml: 2
                    }}
                  />
                </Box>

                <Alert
                  severity={getStatusMessage(result.status, result.jobRequest?.title).color}
                  sx={{
                    mt: 3,
                    borderRadius: 1,
                    fontSize: '1.1rem',
                    backgroundColor: 'rgba(189, 155, 25, 0.05)',
                    border: '1px solid',
                    borderColor: theme => theme.palette[getStatusMessage(result.status, result.jobRequest?.title).color].light,
                    direction: 'rtl',
                    textAlign: 'right'
                  }}
                >
                  {getStatusMessage(result.status, result.jobRequest?.title).message}
                </Alert>

                {result.resumeOrDocuments && (
                  <Button
                    fullWidth
                    variant="outlined"
                    component={Link}
                    href={`https://api.asiamehr.com${result.resumeOrDocuments}`}
                    target="_blank"
                    rel="noopener"
                    sx={{
                      mt: 3,
                      py: 1.5,
                      fontSize: '1.1rem',
                      borderColor: 'rgb(107, 142, 35)',
                      color: 'rgb(107, 142, 35)',
                      '&:hover': {
                        borderColor: 'rgb(85, 115, 28)',
                        color: 'rgb(85, 115, 28)',
                        backgroundColor: 'rgba(107, 142, 35, 0.04)'
                      }
                    }}
                  >
                    مشاهده رزومه ارسالی
                  </Button>
                )}
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
      <Footer/>
    </>
  );
};

export default CheckJobApp;